<template>
<div>
    <span style="width: 100%;text-align: center"> LISTE DES COMPTES COLLECTE AGENT DU TERRAINS </span>

    <v-container fluid class="down-top-padding">
        <v-card>
            <v-card-text class="pa-2">

                <v-data-table :headers="headers" :items="list" sort-by="id" class="contact-listing-app" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>

                            <v-row>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche...." filled background-color="transparent" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="700">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" dark class="mb-2 text-capitalize" v-show="true" v-on="on" @click="close()">
                                        Nouveau
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="pa-4 info">
                                        <span class="title white--text">Formulaire</span>
                                    </v-card-title>

                                    <v-card-text>

                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field outlined hide-details v-model="editedItem.nom" label="Nom *"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field outlined hide-details v-model="editedItem.prenom" label="Prenom *"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field outlined hide-details v-model="editedItem.email" label="Email *"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field outlined v-model="editedItem.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" label="password *" @click:append="show1 = !show1">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-select :items="listprofil" outlined hide-details item-value="id" v-model="selectprofil" label="List des profils *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle_profil }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle_profil }}
                                                        </template>
                                                    </v-select>
                                                </v-col>

                                                <v-col cols="12" sm="6">
                                                    <v-select :items="listdirection" outlined hide-details item-value="id" v-model="selectdirection" label="List des directions *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle_ddaep }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle_ddaep }}
                                                        </template>
                                                    </v-select>
                                                </v-col>

                                            </v-row>

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" dark @click="dialog=false">Cancel</v-btn>
                                        <v-btn color="success" dark @click="Save">Enregistrer</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialog2" max-width="1000">

                                <v-card>
                                    <v-card-title class="pa-4 info">
                                        <span class="title white--text">{{ detail_Formulaire }}</span>
                                    </v-card-title>

                                    <v-card-text>

                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-row>

                                                        <v-col cols="12">
                                                    <v-select :items="listcommune" @change="fuseSearchcommune" outlined hide-details item-value="id" v-model="selectcommune" label="Commune *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12"  sm="6">
                                                    <v-select :items="listarrondissement" @change="fuseSearcharrondissement" outlined hide-details item-value="id" v-model="selectarrondissement" label="Arrondissement *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>

                                                    </v-select>
                                                </v-col>

                                                <v-col cols="12"  sm="6">
                                                    <v-select :items="listvillage" outlined hide-details item-value="id" v-model="selectvillage" label="Village/Ville *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>

                                                    </v-select>
                                                </v-col>

                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-data-table :headers="headers2" :items="list2" sort-by="id" class="contact-listing-app">
                                                    </v-data-table>
                                                </v-col>

                                            </v-row>

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" dark @click="dialog2=false">Cancel</v-btn>
                                        <v-btn color="success" dark @click="save_zone">Enregistrer</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>

                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex">

                            <v-icon large class="mb-2 mr-2 info--text" @click="getitem(item)">mdi-pencil</v-icon>
                            <v-icon large class="mb-2 mr-2 error--text" @click="deletItem(item)">mdi-delete</v-icon>
                            <v-icon large class="mb-2 mr-2 success--text" @click="getdetails(item)">mdi-sitemap-outline</v-icon>
                        </div>
                    </template>

                    <template v-slot:no-data>

                    </template>
                    <template v-slot:[`item.status_compte`]="{ item }">
                        <v-btn v-if="item.status_compte==1" color="success" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                            Actif</v-btn>
                        <v-btn v-else color="error" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                            Inactif</v-btn>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "ProjectList",
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '4%'

            },
            {
                text: "Id",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '5%'
            },
            {
                text: "NOM",
                align: "start",
                sortable: false,
                value: "nom",
                divider: true,
                width: '150px'
            },
            {
                text: "PRENOM",
                align: "start",
                sortable: false,
                value: "prenom",
                divider: true,
                width: '150px'
            },

            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "email",
                divider: true,
                width: '150px'
            },
            {
                text: "PROFIL DE COMPTE",
                align: "start",
                sortable: false,
                value: "profil.libelle_profil",
                divider: true,
                width: '150px'
            },
            {
                text: "DIRECTION",
                align: "start",
                sortable: false,
                value: "directions.libelle_ddaep",
                divider: true,
                width: '100px'
            },

            {
                text: "status",
                value: "status_compte",
                divider: true,
                width: '50px'
            },

        ],
        headers2: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '4%'

            },
            {
                text: "Id",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '5%'
            },
            {
                text: "Ville/Village",
                align: "start",
                sortable: false,
                value: "village.libelle",
                divider: true,
                width: '150px'
            },
            {
                text: "Arrondissement",
                align: "start",
                sortable: false,
                value: "village.arrondissement.libelle",
                divider: true,
                width: '150px'
            },
            {
                text: "Commune",
                align: "start",
                sortable: false,
                value: "village.arrondissement.commune.libelle",
                divider: true,
                width: '150px'
            },
            

            {
                text: "status",
                value: "status_compte",
                divider: true,
                width: '50px'
            },

        ],
        show1: true,

        editedItem: {
            id: '',
            nom: '',
            prenom: '',
            email: '',
            password: '',
            id_direction: '',

        },

        list: [],
        listdirection: [],
        listcommune: [],
        listarrondissement: [],
        listvillage: [],

        listarrondissement2: [],
        listvillage2: [],
        listprofil: [],

        etat: false,
        etat2: false,
        selectcommune: '0',
        selectarrondissement: '0',
        selectvillage: '0',
        selectdirection: '0',
        selectprofil: '0',
        id_comptes: '0',
        

    }),

    watch: {

    },

    created() {
        //this.readAll_geographie();
        //this.readAll_Survey();
        this.readAll();
        this.readAll_globale_localisation();
        // this.readAll_Export_Excel();
    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            //fd.append('type_direction', 'ATDA');
            const data = await api.createUpdatedata('admin-backoffice/compte-agent-all', fd);
            this.list = data.succes;

        },

        readAll_globale_localisation: async function () {
            let fd = new FormData();
            fd.append('type_direction', '');

            const data = await api.readAll('admin-backoffice/globale-localisation');
            this.listdirection = data.ddaep;
            this.listcommune = data.commune;
            this.listarrondissement2 = data.arrondissement;
            this.listvillage2 = data.village;

            const data2 = await api.createUpdatedata('admin-backoffice/all-profil', fd);
            this.listprofil = data2.profil.filter(this.parcourre_type_direction);

        },

        Save: async function () {

            let fd = new FormData();
            fd.append('nom', this.editedItem.nom);
            fd.append('prenom', this.editedItem.prenom);
            fd.append('email', this.editedItem.email);
            fd.append('password', this.editedItem.password);
            fd.append('id_profil', this.selectprofil);
            fd.append('id_directions', this.selectdirection);
            //fd.append('type_direction', 'ATDA');
            if (this.etat == false) {
                const data = await api.createUpdatedata('admin-backoffice/compte-agent-register', fd);
                if (data.status == true) {
                    this.showAlert('Enregistrement effectué avec succès', 'success')
                    this.clearitem();

                } else if (data.status == false) {
                    this.showAlert(data.success.message, 'error')
                }

            } else {
                const data = await api.createUpdatedata('admin-backoffice/compte-agent-update/' + this.editedItem.id, fd);
                if (data.status == true) {
                    this.showAlert('Modification effectuée avec succès', 'success')
                    this.clearitem();
                    this.dialog = false;

                } else if (data == false) {
                    this.showAlert('Modification non effectuée', 'error')
                }

            }

            this.readAll();

        },

        async changeStatus(item) {

            const data = await api.readAll('admin-backoffice/compte-agent-status/' + item.id);
            if (data.status == true) {
                this.showAlert('Modification effectuée avec succès', 'success')

            } else if (data.status == false) {
                this.showAlert('Modification non effectuée', 'error')
            }
            this.readAll();
        },
        getdetails: async function (item) {
            console.log(item);
          //  this.dialog2 = true;
            this.detail_Formulaire= item.email;
            this.id_comptes = item.id;  
            //this.$router.push('account-agent-ddaep-zone');
            this.$router.push({path: 'account-agent-ddaep-zone', query: {id_comptes: item.id}});
           /* let fd = new FormData();
            fd.append('id_comptes', item.id);
            const data= await api.createUpdatedata('admin-backoffice/all-association-compte-agent-lieu',fd);*/

           // this.list2 = data.compte;
            //this.getdetails2();
          
        },

        getdetails2: async function () {
          
            let fd = new FormData();
            fd.append('id_comptes',  this.id_comptes);
            const data= await api.createUpdatedata('admin-backoffice/all-association-compte-agent-lieu',fd);

            this.list2 = data.compte;
          
        },

        save_zone: async function () {
            let fd = new FormData();
            fd.append('id_comptes', this.id_comptes);
            fd.append('id_villages', this.selectvillage);
            fd.append('type_compte', 'DDAEP');
            if(this.etat2==false){
                const data = await api.createUpdatedata('admin-backoffice/add-association-compte-agent-lieu', fd);
                if (data.status == true) {
                    this.showAlert('Enregistrement effectué avec succès', 'success')
                    this.clearitem();

                } else if (data.status == false) {
                    this.showAlert(data.success.message, 'error')
                }
            }else{
                const data = await api.createUpdatedata('admin-backoffice/update-association-compte-agent-lieu/' + this.editedItem.id, fd);
                if (data.status == true) {
                    this.showAlert('Modification effectuée avec succès', 'success')
                    this.clearitem();
                    this.dialog = false;

                } else if (data.status == false) {
                    this.showAlert('Modification non effectuée', 'error')
                }
            }
            this.getdetails2();
            
        },

        

        deletItem: async function (item) {

            this.$swal.fire({
                title: 'Vous etes sur de vouloir supprimer cet enregistrement!',
                text: "Agent DDAEP",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    const data = await api.readAll('admin-backoffice/compte-agent-delete/' + item.id);
                    if (data.status == true) {
                        this.showAlert('Suppression effectuée avec succès', 'success')
                        this.clearitem();
                        this.dialog = false;

                    } else if (data.status == false) {
                        this.showAlert('Suppression non effectuée', 'error')
                    }
                    this.readAll();

                }
            })

        },

        clearitem() {
            this.editedItem.nom = '';
            this.editedItem.prenom = '';
            this.editedItem.email = '';
            this.editedItem.password = '';
            this.selectdirection = '0';
            this.selectprofil = '0';
            this.etat = false;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        getitem(item) {
            this.etat = true;

            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.selectdirection = item.id_directions;
        },

        fetchData() {
            this.readAll();
        },

        fuseSearchcommune: function () {
            this.listarrondissement = this.listarrondissement2.filter(this.parcourre_arrondissement);
            this.listvillage = [];

        },

        fuseSearcharrondissement: function () {
            this.listvillage = this.listvillage2.filter(this.parcourre_village);
        },

        parcourre_type_direction: function (obj) {
            if (obj.type_direction == 'DDAEP-AGENT') {
                return true;
            } else {
                return false;
            }
        },
        parcourre_arrondissement: function (obj) {
            if (obj.id_communes == this.selectcommune) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_village: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement) {
                return true;
            } else {
                return false;
            }
        },

    },
};
</script>
